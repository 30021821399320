import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    _isMobile,
    _getDomain,
    _getPlatform,
    _isAuthEnabled,
} from "./helpers.js";
import { useLocation } from "react-router-dom";
import useTownNavigate from "../hooks/useTownNavigate.js";
import { setOpenModalAuth, login, saveLogin } from "../redux/actions/user";
import { closeMobileMenu } from "../redux/actions/header";
import { closeMiniCart } from "../redux/actions/miniCart";
import {
    Alert,
    Button,
    IconButton,
    CircularProgress,
    Dialog,
    Slide,
    TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "../css/auth-modal.css";
import axios from "axios";
import bridge from "@vkontakte/vk-bridge";
import { Connect, ConnectEvents, Config } from "@vkontakte/superappkit";
import clsx from "clsx";
import {
    YandexAuthCaptcha,
    YandexRecallCaptcha,
    YandexResmsCaptcha,
} from "./YandexCaptcha.jsx";
import useChoosenTown from "../hooks/useChoosenTown.js";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { updateAlerts } from "../redux/actions/systemAlerts.js";
import useMetricFunctions from "../hooks/useMetricFunctions.js";
import useApiRequests from "../hooks/useApiRequests.js";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AuthModal() {
    const captchaTimer = useRef(null);

    const { choosenTown } = useChoosenTown();
    const dispatch = useDispatch();
    const { navigateTo } = useTownNavigate();
    const { pathname } = useLocation();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const { metricVisitCheckout } = useMetricFunctions();
    const { getRequestAPI, postRequestAPI } = useApiRequests();

    const config = useSelector((state) => state.config.data);

    const user = useSelector((state) => state.user.user);

    const { openModalAuth } = useSelector(({ user }) => {
        return {
            openModalAuth: user.openModalAuth,
        };
    });

    const { miniCartOpen } = useSelector((state) => state.miniCart);

    const inputCode = React.useRef([]);
    const inputPhone = React.useRef();
    const VKIDAuthWrapper = React.useRef();
    const authSmartCaptchaWidgetId = React.useRef();
    const recallSmartCaptchaWidgetId = React.useRef();
    const resmsSmartCaptchaWidgetId = React.useRef();
    const floatingOneTapButtonRef = React.useRef();

    const [loading, setLoading] = React.useState();
    const [error, setError] = React.useState(null);
    const [authPhone, setAuthPhone] = React.useState();
    const [isAuthPhoneCode, setIsAuthPhoneCode] = React.useState(false);
    const [authPhoneCode, setAuthPhoneCode] = React.useState(["", "", "", ""]);
    const [verifyPhone, setVerifyPhone] = React.useState(false);
    const [recallInterval, setRecallInterval] = React.useState(false);
    const [recallTimer, setRecallTimer] = React.useState(60);
    const [recallActive, setRecallActive] = React.useState(false);
    const [VKUserData, setVKUserData] = useState({});
    const [isVKBtnRendered, setIsVKBtnRendered] = useState(false);

    const isVkAuthEnabled =
        !!config.CONFIG_vk_auth_appId &&
        config.CONFIG_vk_auth_status === "active";

    useEffect(() => {
        if (isVkAuthEnabled) {
            Config.init({
                appId: config.CONFIG_vk_auth_appId,
                appSettings: {
                    agreements: "",
                    promo: "",
                    vkc_behavior: "",
                    vkc_auth_action: "",
                    vkc_brand: "",
                    vkc_display_mode: "",
                },
            });
        }
    }, []);

    const checkVKNotificationsPermission = () => {
        bridge
            .send("VKWebAppStorageGet", {
                keys: ["notificationsPermission"],
            })
            .then((data) => {
                data.keys[0].key === "notificationsPermission" ||
                    setTimeout(() => {
                        bridge
                            .send("VKWebAppAllowNotifications")
                            .then((data) => {
                                data.result &&
                                    bridge.send("VKWebAppStorageSet", {
                                        key: "notificationsPermission",
                                    });
                            });
                    }, 1000);
            });
    };

    const saveVKUserData = (user) => {
        if (
            (!user.name && VKUserData.name) ||
            (!user.vk && VKUserData.vk) ||
            (!user.dayBirthday && VKUserData.dayBirthday) ||
            (!user.monthBirthday && VKUserData.monthBirthday)
        ) {
            const updatedUser = { ...user };
            if (!user.name && VKUserData.name) {
                updatedUser.name = VKUserData.name;
            }
            if (!user.vk && VKUserData.vk) {
                updatedUser.vk = VKUserData.vk;
            }
            if (!user.dayBirthday && VKUserData.dayBirthday) {
                updatedUser.dayBirthday = VKUserData.dayBirthday;
            }
            if (!user.monthBirthday && VKUserData.monthBirthday) {
                updatedUser.monthBirthday = VKUserData.monthBirthday;
            }

            postRequestAPI("saveLogin", "", {
                ...updatedUser,
            }).then((resp) => {
                console.log(resp);
                dispatch(saveLogin(resp.data.user));
            });
        }
    };

    let consoleRedirectUrl = choosenTown
        ? choosenTown.url
        : "https://" + _getDomain();

    if (_getPlatform() === "vk") {
        consoleRedirectUrl += "?rest-api=vk_start";
    }

    console.log(consoleRedirectUrl);

    const oneTapButton = Connect.buttonOneTapAuth({
        callback: function (event) {
            const { type } = event;

            if (!type) {
                return;
            }

            switch (type) {
                case ConnectEvents.OneTapAuthEventsSDK.LOGIN_SUCCESS:
                    handleVkAuth(event);
                    return;
                case ConnectEvents.ButtonOneTapAuthEventsSDK.SHOW_LOGIN:
                    let redirectUrl = choosenTown
                        ? choosenTown.url
                        : "https://" + _getDomain();

                    if (_getPlatform() === "vk") {
                        redirectUrl += "?rest-api=vk_start";
                    }

                    return Connect.redirectAuth({
                        url: redirectUrl,
                    });
            }
            return;
        },

        options: {
            showAlternativeLogin: false,
            showAgreements: true,
            displayMode: "default",
            langId: 0,
            buttonSkin: "primary",
            buttonStyles: {
                borderRadius: 20,
                height: 55,
            },
        },
    });

    useEffect(() => {
        if (!openModalAuth && isVKBtnRendered) {
            setIsVKBtnRendered(false);
        }

        if (isVkAuthEnabled && !isVKBtnRendered && openModalAuth) {
            setTimeout(() => {
                if (VKIDAuthWrapper.current) {
                    VKIDAuthWrapper.current.innerHTML = "";
                    VKIDAuthWrapper.current.append(oneTapButton.getFrame());
                    oneTapButton.authReadyPromise.then(() => {
                        setIsVKBtnRendered(true);
                    });
                }
            });
        }
    }, [openModalAuth]);

    // Рендер плавающего окна авторизации ВК
    useEffect(() => {
        let url = new URL(window.location.href);
        if (
            isVkAuthEnabled &&
            config.CONFIG_auth_vk_popup === "active" &&
            !user.token &&
            !url.searchParams.has("payload")
        ) {
            const floatingOneTapButton = Connect.floatingOneTapAuth({
                callback: (event) => {
                    if (
                        event.type ===
                        ConnectEvents.OneTapAuthEventsSDK.LOGIN_SUCCESS
                    ) {
                        handleVkAuth(event);
                        floatingOneTapButton.destroy();
                    }
                },
                options: {
                    styles: {
                        zIndex: 999,
                    },
                },
            });

            floatingOneTapButtonRef.current = floatingOneTapButton;

            if (floatingOneTapButton) {
                document.body.appendChild(floatingOneTapButton.getFrame());
                setTimeout(() => {
                    if (floatingOneTapButton.getFrame()) {
                        floatingOneTapButton.getFrame().style.transition =
                            "opacity ease-in-out .3s ";
                        floatingOneTapButton.getFrame().style.opacity = 0;
                        setTimeout(() => floatingOneTapButton.destroy(), 500);
                    }
                }, 30000);
            }
        }
    }, []);

    //Удаляем плавающее окно ВК после входа
    useEffect(() => {
        if (user.token && floatingOneTapButtonRef.current) {
            floatingOneTapButtonRef.current?.destroy();
        }
    }, [user.token]);

    useEffect(() => {
        if (isVkAuthEnabled) {
            if (isAuthPhoneCode) {
                oneTapButton.destroy();
                setIsVKBtnRendered(false);
            } else {
                if (!isVKBtnRendered) {
                    setTimeout(() => {
                        if (VKIDAuthWrapper.current) {
                            VKIDAuthWrapper.current.innerHTML = "";
                            VKIDAuthWrapper.current.append(
                                oneTapButton.getFrame()
                            );

                            oneTapButton.authReadyPromise.then(() => {
                                setIsVKBtnRendered(true);
                            });
                        }
                    });
                }
            }
        }
    }, [isAuthPhoneCode]);

    // Авторизация после редиректа с VK ID
    useEffect(() => {
        let url = new URL(window.location.href);
        if (url.searchParams.has("payload")) {
            let payload;
            try {
                payload = JSON.parse(url.searchParams.get("payload"));
            } catch (e) {
                console.log(e);
            }
            if (payload && payload.uuid && payload.token) {
                handleVkAuth({ payload });
                dispatch(
                    updateAlerts({
                        open: true,
                        message: "Входим в личный кабинет",
                    })
                );
            }
        }
    }, []);

    useEffect(() => {
        bridge.send("VKWebAppGetUserInfo").then((VKUserInfo) => {
            const existingVkData = {};

            existingVkData.name = VKUserInfo.first_name || "";
            existingVkData.vk = VKUserInfo.id
                ? "https://vk.com/id" + VKUserInfo.id
                : "";
            existingVkData.dayBirthday = VKUserInfo.bdate?.split(".")[0] || "";
            existingVkData.monthBirthday =
                VKUserInfo.bdate?.split(".")[1] || "";

            setVKUserData(existingVkData);
        });
    }, []);

    const startRecallTimer = () => {
        stopRecallTimer();
        setRecallActive(true);
        setRecallInterval(
            setInterval(() => {
                setRecallTimer((prevTimer) => --prevTimer);
            }, 1000)
        );
    };
    const stopRecallTimer = () => {
        setRecallActive(false);
        setRecallTimer(60);
        if (recallInterval) clearInterval(recallInterval);
    };
    if (recallActive && recallTimer < 1) {
        stopRecallTimer();
    }

    let dialogAuthProps = { open: openModalAuth, keepMounted: false };
    if (_isMobile()) {
        dialogAuthProps.TransitionComponent = Transition;
        dialogAuthProps.fullScreen = true;
    }

    const handleClose = () => {
        dispatch(setOpenModalAuth(false));
    };

    const handleVkAuth = (event) => {
        setLoading(true);

        const queryParams =
            "&uuid=" + event.payload.uuid + "&token=" + event.payload.token;

        getRequestAPI("vkAuth", queryParams, { mode: "no-cors" }).then(
            (resp) => {
                console.log(resp.data);
                if (resp.data.status === "success") {
                    dispatch(login(resp.data.user));
                    setError(null);
                    if (pathname.includes("/cart") || miniCartOpen) {
                        navigateTo("/checkout");
                    }
                    dispatch(closeMiniCart());
                    dispatch(setOpenModalAuth(false));
                    dispatch(closeMobileMenu());
                    dispatch(
                        updateAlerts({
                            open: false,
                            message: "",
                        })
                    );

                    // saveVKUserData(resp.data.user);

                    checkVKNotificationsPermission();
                } else {
                    setError({
                        status: resp.data.status,
                        message: resp.data.message,
                    });
                    dispatch(
                        updateAlerts({
                            open: true,
                            message: resp.data.message,
                        })
                    );
                }

                setLoading(false);
            }
        );
    };

    const handleLoginBtnClick = async () => {
        if (verifyPhone && error?.status !== "error_captcha") {
            if (config.CONFIG_auth_captcha_type === "yandex") {
                if (!window.smartCaptcha) {
                    setError({
                        status: "error",
                        message: "Ошибка: Отсутствует скрипт smartCaptcha",
                    });
                    return;
                }
                if (!config.CONFIG_auth_smartcaptcha_site_token) {
                    setError({
                        status: "error",
                        message: "Ошибка: Отсутствует токен smartCaptcha",
                    });
                    return;
                }
                setLoading(true);
                captchaTimer.current = setTimeout(() => {
                    setError({
                        status: "error",
                        message:
                            "Ошибка: Не удалось получить токен smartCaptcha",
                    });
                    setLoading(false);
                    return;
                }, 4000);
                console.log("await for yandex captcha to execute");
                window.smartCaptcha?.execute(authSmartCaptchaWidgetId.current);
            } else if (config.CONFIG_auth_captcha_type === "google") {
                const captchaToken = await getReCaptchaToken();
                handleAuth(captchaToken);
            }
        }
    };

    const handleRecallBtnClick = async () => {
        if (verifyPhone) {
            setError(null);
            setLoading(true);

            if (config.CONFIG_auth_captcha_type === "yandex") {
                console.log("await for yandex captcha to execute");
                window.smartCaptcha?.execute(
                    recallSmartCaptchaWidgetId.current
                );
            } else if (config.CONFIG_auth_captcha_type === "google") {
                const captchaToken = await getReCaptchaToken();
                handleRecall(captchaToken);
            }
        }
    };

    const handleResmsBtnClick = async () => {
        if (verifyPhone) {
            setError(null);
            setLoading(true);

            if (config.CONFIG_auth_captcha_type === "yandex") {
                console.log("await for yandex captcha to execute");
                window.smartCaptcha?.execute(resmsSmartCaptchaWidgetId.current);
            } else if (config.CONFIG_auth_captcha_type === "google") {
                const captchaToken = await getReCaptchaToken();
                handleResms(captchaToken);
            }
        }
    };

    const handleAuth = (captchaToken) => {
        const phone = getNumbersValue(inputPhone.current?.value);

        const restApiEndpoint =
            config.CONFIG_auth_type === "sms"
                ? "verifyCodeSms"
                : "verifyCodeRobocall";

        const queryParams =
            "&phone=" + phone + "&recaptchaResponse=" + captchaToken;

        getRequestAPI(restApiEndpoint, queryParams, { mode: "no-cors" }).then(
            (resp) => {
                if (config.CONFIG_auth_captcha_type === "yandex") {
                    window.smartCaptcha?.reset(
                        authSmartCaptchaWidgetId.current
                    );
                }
                if (resp.data.status === "success") {
                    setIsAuthPhoneCode(true);
                    setError(null);
                    startRecallTimer();
                } else
                    setError({
                        status: resp.data.status,
                        message: resp.data.text,
                    });
                setLoading(false);
            }
        );
    };

    const handleRecall = (captchaToken) => {
        const phone = getNumbersValue(inputPhone.current?.value);

        const queryParams =
            "&phone=" + phone + "&recaptchaResponse=" + captchaToken;

        getRequestAPI("verifyCodeRobocallAgain", queryParams, {
            mode: "no-cors",
        }).then((resp) => {
            if (config.CONFIG_auth_captcha_type === "yandex") {
                window.smartCaptcha?.reset(recallSmartCaptchaWidgetId.current);
            }
            setLoading(false);
            startRecallTimer();
            if (resp.data.status !== "success") {
                setError({
                    status: resp.data.status,
                    message: resp.data.text,
                });
            }
        });
    };

    const handleResms = (captchaToken) => {
        const phone = getNumbersValue(inputPhone.current?.value);

        const queryParams =
            "&phone=" + phone + "&recaptchaResponse=" + captchaToken;

        getRequestAPI("verifyCodeSms", queryParams, { mode: "no-cors" }).then(
            (resp) => {
                if (config.CONFIG_auth_captcha_type === "yandex") {
                    window.smartCaptcha?.reset(
                        resmsSmartCaptchaWidgetId.current
                    );
                }
                setLoading(false);
                startRecallTimer();
                if (resp.data.status !== "success") {
                    setError({
                        status: resp.data.status,
                        message: resp.data.text,
                    });
                }
            }
        );
    };

    const handleChangeNumber = () => {
        if (recallInterval) {
            clearInterval(recallInterval);
        }

        setAuthPhoneCode(["", "", "", ""]);
        setIsAuthPhoneCode(!isAuthPhoneCode);
    };

    const handleCodeChange = (e, inputIndex) => {
        const { value } = e.target;

        if (
            /^[0-9]*$/.test(value) &&
            (authPhoneCode[inputIndex] + value).length === 1 &&
            !loading
        ) {
            const newAuthPhoneCode = [...authPhoneCode];
            newAuthPhoneCode[inputIndex] = value;
            setAuthPhoneCode(newAuthPhoneCode);

            const nextSibling = inputCode.current.find(
                (input) => input.value === ""
            );

            if (nextSibling && value !== "") {
                nextSibling.focus();
            }

            if (newAuthPhoneCode.every((value) => value !== "")) {
                const phone = getNumbersValue(authPhone);
                setLoading(true);

                const queryParams =
                    "&phone=" + phone + "&code=" + newAuthPhoneCode.join("");

                getRequestAPI("loginByPhoneCode", queryParams, {
                    mode: "no-cors",
                }).then((resp) => {
                    if (resp.data.status === "success") {
                        dispatch(login(resp.data.user));
                        setError(null);
                        if (pathname.includes("/cart") || miniCartOpen) {
                            metricVisitCheckout();
                            navigateTo("/checkout");
                        }
                        dispatch(closeMiniCart());
                        dispatch(setOpenModalAuth(false));
                        dispatch(closeMobileMenu());
                        // Сбрасываем форму логина
                        setAuthPhoneCode(["", "", "", ""]);
                        setIsAuthPhoneCode(false);
                        setAuthPhone("");
                        setVerifyPhone(false);

                        // saveVKUserData(resp.data.user);

                        // checkVKNotificationsPermission();
                    } else
                        setError({
                            status: resp.data.status,
                            message: resp.data.text,
                        });
                    setLoading(false);
                });
            }
        }
    };

    const handleEnterCode = (e, inputIndex) => {
        if (
            e.code === "Backspace" &&
            inputIndex !== 0 &&
            inputCode.current[inputIndex].value === ""
        ) {
            const previousInput = inputCode.current[inputIndex - 1];
            previousInput.focus();

            const newAuthPhoneCode = [...authPhoneCode];
            newAuthPhoneCode[inputIndex - 1] = "";
            setAuthPhoneCode(newAuthPhoneCode);
        }
    };

    const getNumbersValue = function (input) {
        return input.replace(/\D/g, "");
    };
    const handlePhonePaste = function (e) {
        var input = e.target,
            inputNumbersValue = getNumbersValue(input.value);
        var pasted = e.clipboardData || window.clipboardData;
        if (pasted) {
            var pastedText = pasted.getData("Text");
            if (/\D/g.test(pastedText)) {
                input.value = inputNumbersValue;
                return;
            }
        }
    };
    const handlePhoneInput = function (e) {
        var input = e.target,
            inputNumbersValue = getNumbersValue(input.value),
            selectionStart = input.selectionStart,
            formattedInputValue = "";

        if (!inputNumbersValue) {
            return (input.value = "");
        }

        if (input.value.length !== selectionStart) {
            if (e.data && /\D/g.test(e.data)) {
                input.value = inputNumbersValue;
            }
            return;
        }

        if (["7", "8", "9"].indexOf(inputNumbersValue[0]) > -1) {
            if (inputNumbersValue[0] === "9")
                inputNumbersValue = "7" + inputNumbersValue;
            var firstSymbols = inputNumbersValue[0] === "8" ? "8" : "+7";
            formattedInputValue = input.value = firstSymbols + " ";
            if (inputNumbersValue.length > 1) {
                formattedInputValue += "(" + inputNumbersValue.substring(1, 4);
            }
            if (inputNumbersValue.length >= 5) {
                formattedInputValue += ") " + inputNumbersValue.substring(4, 7);
            }
            if (inputNumbersValue.length >= 8) {
                formattedInputValue += "-" + inputNumbersValue.substring(7, 9);
            }
            if (inputNumbersValue.length >= 10) {
                formattedInputValue += "-" + inputNumbersValue.substring(9, 11);
            }
        } else {
            formattedInputValue = "+" + inputNumbersValue.substring(0, 16);
        }
        input.value = formattedInputValue;
        setAuthPhone(formattedInputValue);
        inputNumbersValue.length >= 11
            ? setVerifyPhone(true)
            : setVerifyPhone(false);
    };
    const handlePhoneKeyDown = function (e) {
        var inputValue = e.target.value.replace(/\D/g, "");
        if (e.keyCode === 8 && inputValue.length === 1) {
            e.target.value = "";
        }

        if (
            e.key === "Enter" &&
            verifyPhone &&
            error?.status !== "error_captcha"
        ) {
            handleLoginBtnClick();
        }
    };

    const onVerifyAuth = useCallback((token) => {
        clearInterval(captchaTimer.current);
        console.log(
            "🚀 ~ onVerifyAuth ~ captchaType:",
            config.CONFIG_auth_captcha_type,
            ",userToken:",
            token
        );
        handleAuth(token);
    }, []);

    const onVerifyRecall = useCallback((token) => {
        console.log(
            "🚀 ~ onVerifyRecall ~ captchaType:",
            config.CONFIG_auth_captcha_type,
            ",userToken:",
            token
        );
        handleRecall(token);
    }, []);

    const onVerifyResms = useCallback((token) => {
        console.log(
            "🚀 ~ onVerifyResms ~ captchaType:",
            config.CONFIG_auth_captcha_type,
            ",userToken:",
            token
        );
        handleResms(token);
    }, []);

    const getReCaptchaToken = async () => {
        console.log("await for google captcha to execute");
        try {
            const userToken = await executeRecaptcha("auth");

            console.log(
                "🚀 ~ onVerify ~ captchaType:",
                config.CONFIG_auth_captcha_type,
                ",userToken:",
                userToken
            );
            return userToken;
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <>
            {config.CONFIG_auth_captcha_type === "yandex" &&
            !user.token &&
            window.smartCaptcha &&
            config.CONFIG_auth_type !== "noauth" ? (
                <div id="captcha-container">
                    <div id="auth-captcha-container">
                        <YandexAuthCaptcha
                            onVerifyAuth={onVerifyAuth}
                            authSmartCaptchaWidgetId={authSmartCaptchaWidgetId}
                            smartCaptchaToken={
                                config.CONFIG_auth_smartcaptcha_site_token
                            }
                            updateError={setError}
                        />
                    </div>
                    <div id="recall-captcha-container">
                        <YandexRecallCaptcha
                            onVerifyRecall={onVerifyRecall}
                            recallSmartCaptchaWidgetId={
                                recallSmartCaptchaWidgetId
                            }
                            smartCaptchaToken={
                                config.CONFIG_auth_smartcaptcha_site_token
                            }
                        />
                    </div>
                    <div id="resms-captcha-container">
                        <YandexResmsCaptcha
                            onVerifyResms={onVerifyResms}
                            resmsSmartCaptchaWidgetId={
                                resmsSmartCaptchaWidgetId
                            }
                            smartCaptchaToken={
                                config.CONFIG_auth_smartcaptcha_site_token
                            }
                        />
                    </div>
                </div>
            ) : null}

            <Dialog
                {...dialogAuthProps}
                onClose={(event, reason) => {
                    if (reason === "escapeKeyDown") {
                        handleClose();
                    }
                    if (
                        reason === "backdropClick" &&
                        config.CONFIG_close_modal_by_cross !== "active"
                    ) {
                        handleClose();
                    }
                }}
                sx={{
                    "& .MuiPaper-root": {
                        borderRadius: _isMobile() ? "0px" : "20px",
                    },
                }}
            >
                <div
                    className={clsx(
                        "auth-modal",
                        _getPlatform() === "vk" && "vk"
                    )}
                >
                    {loading && (
                        <div className="loader-wrapper">
                            <CircularProgress variant="determinate" />
                        </div>
                    )}
                    <h2 className="auth-modal--title">Авторизация</h2>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        className="modal-close"
                    >
                        <CloseIcon />
                    </IconButton>

                    {error && (
                        <Alert severity="error" sx={{ mb: 2 }}>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: error.message.replace(
                                        /\n/g,
                                        "<br />"
                                    ),
                                }}
                            ></div>
                            {error?.status === "error_captcha" ? (
                                <Button
                                    variant="button"
                                    className=" btn--action"
                                    onClick={() => window.location.reload()}
                                    sx={{
                                        width: "100%",
                                        maxHeight: "34px",
                                        mt: "8px",
                                    }}
                                >
                                    Обновить
                                </Button>
                            ) : null}
                        </Alert>
                    )}

                    <div className="phone-auth-wrapper">
                        {config.CONFIG_auth_type === "noauth" ||
                        (isVkAuthEnabled &&
                            config.CONFIG_auth_vk_disable_phone === "active" &&
                            _getPlatform() === "vk") ? null : (
                            <TextField
                                disabled={isAuthPhoneCode}
                                onKeyDown={handlePhoneKeyDown}
                                onInput={handlePhoneInput}
                                onPaste={handlePhonePaste}
                                label="Номер телефона"
                                className="phone-input phone-mask"
                                value={authPhone ? authPhone : ""}
                                type={"tel"}
                                id="user-phone"
                                inputRef={inputPhone}
                                autoFocus
                            />
                        )}
                        {!isAuthPhoneCode ? (
                            <div id="recaptcha-container">
                                {config.CONFIG_auth_type === "noauth" ||
                                (isVkAuthEnabled &&
                                    config.CONFIG_auth_vk_disable_phone ===
                                        "active" &&
                                    _getPlatform() === "vk") ? null : (
                                    <Button
                                        variant="button"
                                        onClick={handleLoginBtnClick}
                                        className="btn--action auth-btn"
                                        disabled={
                                            !verifyPhone ||
                                            error?.status === "error_captcha"
                                        }
                                    >
                                        Войти
                                    </Button>
                                )}
                                {isVkAuthEnabled && (
                                    <div className="vkid-auth-wrapper">
                                        {config.CONFIG_auth_type !== "noauth" &&
                                            (config.CONFIG_auth_vk_disable_phone !==
                                                "active" ||
                                                _getPlatform() !== "vk") &&
                                            isVKBtnRendered && (
                                                <p className="auth-modal--secondary-text">
                                                    или
                                                </p>
                                            )}

                                        {!isVKBtnRendered && (
                                            <CircularProgress
                                                className="vkid-btn-loader"
                                                color="vk"
                                            />
                                        )}
                                        <div ref={VKIDAuthWrapper}></div>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div>
                                <div className="auth-modal--info">
                                    {config.CONFIG_auth_type === "sms"
                                        ? "На ваш номер будет отправлен код подтверждения."
                                        : "На ваш номер будет совершен звонок. Для входа введите 4 последние цифры этого номера."}
                                </div>

                                <Button
                                    variant="text"
                                    onClick={handleChangeNumber}
                                    className="phone-auth--change-number btn--action"
                                >
                                    Изменить
                                </Button>
                                <div className="phone-auth--code">
                                    <input
                                        type={_isMobile() ? "number" : "text"}
                                        className="verify-code code-1"
                                        ref={(ref) =>
                                            (inputCode.current[0] = ref)
                                        }
                                        onChange={(e) => handleCodeChange(e, 0)}
                                        value={authPhoneCode[0]}
                                        onKeyDown={(e) => handleEnterCode(e, 0)}
                                        autoComplete="off"
                                        name="code-1"
                                        autoFocus
                                    />
                                    <input
                                        type={_isMobile() ? "number" : "text"}
                                        className="verify-code code-2"
                                        ref={(ref) =>
                                            (inputCode.current[1] = ref)
                                        }
                                        onChange={(e) => handleCodeChange(e, 1)}
                                        value={authPhoneCode[1]}
                                        onKeyDown={(e) => handleEnterCode(e, 1)}
                                        autoComplete="off"
                                        name="code-2"
                                    />
                                    <input
                                        type={_isMobile() ? "number" : "text"}
                                        className="verify-code code-3"
                                        ref={(ref) =>
                                            (inputCode.current[2] = ref)
                                        }
                                        onChange={(e) => handleCodeChange(e, 2)}
                                        value={authPhoneCode[2]}
                                        onKeyDown={(e) => handleEnterCode(e, 2)}
                                        autoComplete="off"
                                        name="code-3"
                                    />
                                    <input
                                        type={_isMobile() ? "number" : "text"}
                                        className="verify-code code-4"
                                        ref={(ref) =>
                                            (inputCode.current[3] = ref)
                                        }
                                        onChange={(e) => handleCodeChange(e, 3)}
                                        value={authPhoneCode[3]}
                                        onKeyDown={(e) => handleEnterCode(e, 3)}
                                        autoComplete="off"
                                        name="code-4"
                                    />
                                </div>

                                <Button
                                    variant="button"
                                    disabled={recallActive}
                                    onClick={
                                        config.CONFIG_auth_type === "sms"
                                            ? handleResmsBtnClick
                                            : handleRecallBtnClick
                                    }
                                    className="phone-auth--recall btn--action"
                                    sx={{ width: 1 }}
                                >
                                    {config.CONFIG_auth_type === "sms"
                                        ? "Отправить код ещё раз"
                                        : "Повторный звонок"}
                                    {recallActive && (
                                        <span className="recall-timout">
                                            через {recallTimer} сек.
                                        </span>
                                    )}
                                </Button>

                                {config.CONFIG_auth_type === "sms" ? null : (
                                    <div className="auth-modal--info">
                                        <b>Не поступил звонок?</b>
                                        <br />
                                        Проверьте правильность номера телефона.
                                    </div>
                                )}

                                {config.CONFIG_auth_type ===
                                "robocallwithsms" ? (
                                    <Button
                                        variant="button"
                                        disabled={recallActive}
                                        onClick={handleResmsBtnClick}
                                        className="phone-auth--resms btn--gray"
                                        sx={{ width: 1, mt: 2 }}
                                    >
                                        Запросить смс
                                        {recallActive && (
                                            <span className="resms-timout">
                                                через {recallTimer} сек.
                                            </span>
                                        )}
                                    </Button>
                                ) : null}
                            </div>
                        )}
                    </div>

                    <div className="auth-modal--footer">
                        <small>
                            Используя сервис, вы принимаете правила{" "}
                            <a
                                href={
                                    choosenTown
                                        ? `${choosenTown.url}/privacy`
                                        : "/privacy"
                                }
                                target="_blank"
                            >
                                политики конфиденциальности
                            </a>{" "}
                            и{" "}
                            <a
                                href={
                                    choosenTown
                                        ? `${choosenTown.url}/offert`
                                        : "/offert"
                                }
                                target="_blank"
                            >
                                договора публичной оферты
                            </a>
                            .
                        </small>
                    </div>
                </div>
            </Dialog>
        </>
    );
}
